import { api } from './api';

export const faenaServices = {
  getFaena,
  getFaenas,
  getDetalleFaenaHtml,
  getSupervisorFaena,
  putFaena,
  postFaena,
  getTrabajadoresInFaena,
  putTrabajadorFaena,
  postCategoriaDocumentoFaena,
  getCategoriaDocumentoFaena,
  postTipoDocumentoFaena,
  getConfiguracionDocumentosFaena,
  putDocumentoFaena,
  deleteDocumentoFaena,
  getDocBitacoraFaena,
  getDocumentosFaena,
  putCategoriaDocumentoFaena,
  putTipoDocumentoFaena,
  getDocumentosHistoricosFaena,
  getTipoDocumentoFaena
}

function getTipoDocumentoFaena(data){
  return api.post(
    api.tx.getTipoDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentosHistoricosFaena(data){
  return api.post(
    api.tx.getDocumentosHistoricosFaena, 
    data
  ).then(data => {
    return data;
  });
}

function putTipoDocumentoFaena(data){
  return api.post(
    api.tx.putTipoDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function putCategoriaDocumentoFaena(data){
  return api.post(
    api.tx.putCategoriaDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentosFaena(data){
  return api.post(
    api.tx.getDocumentosFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getDocBitacoraFaena(data){
  return api.post(
    api.tx.getDocBitacoraFaena, 
    data
  ).then(data => {
    return data;
  });
}

function deleteDocumentoFaena(data){
  return api.post(
    api.tx.deleteDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function putDocumentoFaena(data){
  return api.post(
    api.tx.putDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getConfiguracionDocumentosFaena(data){
  return api.post(
    api.tx.getConfiguracionDocumentosFaena, 
    data
  ).then(data => {
    return data;
  });
}

function postTipoDocumentoFaena(data){
  return api.post(
    api.tx.postTipoDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getCategoriaDocumentoFaena(data){
  return api.post(
    api.tx.getCategoriaDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function postCategoriaDocumentoFaena(data){
  return api.post(
    api.tx.postCategoriaDocumentoFaena, 
    data
  ).then(data => {
    return data;
  });
}

function putTrabajadorFaena(data){
  return api.post(
    api.tx.putTrabajadorFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getDetalleFaenaHtml(data){
  return api.post(
    api.tx.getDetalleFaenaHtml, 
    data
  ).then(data => {
    return data;
  });
}

function getFaena(data){
  return api.post(
    api.tx.getFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getFaenas(data){
  return api.post(
    api.tx.getFaenas, 
    data
  ).then(data => {
    return data;
  });
}

function getSupervisorFaena(data){
  return api.post(
    api.tx.getSupervisorFaena, 
    data
  ).then(data => {
    return data;
  });
}

function putFaena(data){
  return api.post(
    api.tx.putFaena, 
    data
  ).then(data => {
    return data;
  });
}

function postFaena(data){
  return api.post(
    api.tx.postFaena, 
    data
  ).then(data => {
    return data;
  });
}

function getTrabajadoresInFaena(data){
  return api.post(
    api.tx.getTrabajadoresInFaena, 
    data
  ).then(data => {
    return data;
  });
}
