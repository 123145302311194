<template>
  <div>
    <a href="javascript:void(0)" @click="verBitacora(row)">Bitácora</a>
      <span v-if="mostrarNoAplica(row)">
        | <a href="javascript:void(0)" style="color:red" v-on:click="modalValidaDocumento(row,'N')">No aplica</a>
      </span>
      <span v-else-if="mostrarAplica(row)">
        | <a  href="javascript:void(0)" style="color:green" v-on:click="modalValidaDocumento(row,'S')">Aplica</a>
      </span>
      <span v-else-if="row.estado == 'A'">
        | <a href="javascript:void(0)" v-on:click="descargarDocumento(row)">Descargar</a>
        <span v-if=" mostrarDocumento(row)" > 
          | <a href="javascript:void(0)" v-on:click="verDocumento(row)">Ver</a></span>
        <span v-if="mostrarModalValidaDocumento()">
          | <a  href="javascript:void(0)" style="color:red" v-on:click="modalValidaDocumento(row, 'R')">Rechazar</a>
        </span>
      </span>
      <span v-else-if="row.estado == 'P'">
        | <a href="javascript:void(0)" v-on:click="descargarDocumento(row)">Descargar</a>
        <span v-if=" mostrarDocumento(row)" > | <a href="javascript:void(0)" v-on:click="verDocumento(row)">Ver</a></span>
        <span v-if="mostrarEliminarDocumento()">
          | <a  href="javascript:void(0)" style="color:red" v-on:click="eliminarDocumento(row)">Eliminar</a>
        </span>
        <span v-if="mostrarModalValidaDocumento()">
          | <a  href="javascript:void(0)" style="color:success" v-on:click="modalValidaDocumento(row, 'A')">Aprobar</a>
        </span>
        <span v-if="mostrarModalValidaDocumento()">
          | <a href="javascript:void(0)" style="color:red" v-on:click="modalValidaDocumento(row, 'R')">Rechazar</a>
        </span>
      </span>
      <span v-else-if="row.estado == 'R'">
        | <a href="javascript:void(0)" v-on:click="descargarDocumento(row)">Descargar</a>
        <span v-if=" mostrarDocumento(row)" > 
          | <a href="javascript:void(0)" v-on:click="verDocumento(row)">Ver</a>
        </span>
      </span>
      <span v-else-if="row.estado == 'V'">
        | <a href="javascript:void(0)" v-on:click="descargarDocumento(row)">Descargar</a>
        <span v-if=" mostrarDocumento(row)" > 
          | <a href="javascript:void(0)" v-on:click="verDocumento(row)">Ver</a>
        </span>
      </span>
  </div>
</template>

<script>

import {userService, globales} from "@/services";

export default {
  name: "AccionesDocumento",
  props: {
    row : {default : null},
  },
  methods : {
    
    modalValidaDocumento(row, estado){
      this.$emit('modal-valida-documento', row, estado);
    }, 
    eliminarDocumento(row){
      this.$emit('eliminar-documento', row);
    },
    descargarDocumento(row){
      this.$emit('descargar-documento', row);
    },
    verDocumento(row){
      this.$emit('ver-documento', row);
    },
    verBitacora(row){
      this.$emit('ver-bitacora', row);
    },
    mostrarDocumento(row){
        if(row.estado == 'A' || row.estado == 'V' || row.estado == 'P' || row.estado == 'R'){
          let extension = globales.getFileExtension(row.nombre);
          if(extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'txt' || extension == 'pdf' || extension == 'gif'){
            return true;
          }
        }
        return false;
    },
    mostrarAplica(row){
      if(row.estado == "N"){
        if(globales.isInArray(
          userService.getUser().perfiles, 
          [globales.perfil.admin, globales.perfil.admin_cliente, globales.perfil.control_contratista, globales.perfil.administrador_sucursal] )){
            if(userService.getUser().permiso == "1"){
              return true;
            }
        }
      }
      
      return false;
    },
    mostrarModalValidaDocumento(){
      if(globales.isInArray(
        userService.getUser().perfiles, 
        [globales.perfil.admin, globales.perfil.admin_cliente, globales.perfil.control_contratista, globales.perfil.administrador_sucursal] )){
          if(userService.getUser().permiso == "1"){
            return true;
          }
      }
      
      return false;
    },

    mostrarNoAplica(row){
      if(row.estado == "F"){
        if(globales.isInArray(
          userService.getUser().perfiles, 
          [globales.perfil.admin, globales.perfil.admin_cliente, globales.perfil.control_contratista, globales.perfil.administrador_sucursal] )){
            if(userService.getUser().permiso == "1"){
              return true;
            }
        }
      }
      return false;
    },

    mostrarEliminarDocumento(){
      if(userService.getUser().permiso == "1"){
        return true;
      }
      return false;
    },
  } 
}
</script>

<style scoped>
</style>