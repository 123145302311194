<template>
  <div id="fileagent">
    <VueFileAgent 
      v-model="file[row.originalIndex]"
      ref="vueFileAgent">
      <template v-slot:file-preview-new  v-if="file[row.originalIndex] == null">
        <div key="new">
          <b-button size="sm" variant="light">
            <b-icon-folder2-open></b-icon-folder2-open>
            {{ texto }}
          </b-button>
        </div>
      </template >
      <template v-slot:after-outer>
        <b-button style="margin-right:5px" size="sm" variant="light" v-if="file[row.originalIndex] != null" @click="uploadFile()">
          <b-icon-upload></b-icon-upload> 
          Cargar
        </b-button> 
      </template >
    </VueFileAgent>
  </div>
</template>

<script>

import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

export default {
  name: "FileAgent",
  props: {
    file : {default : null},
    row : {default : null},
    texto : {default:"Buscar"}
  },
  methods : {
    uploadFile(){
      this.$emit('upload-file', this.row,  this.$refs.vueFileAgent);
    },
  } 
}
</script>

<style scoped>
  #fileagent >>> .vue-file-agent {
    border: 0;
    text-align: left;
  }

  .vdp-datepicker >>> input {
    padding: 0.75em 0.5em !important;
    font-size: 100% !important;
    border: 1px solid #ccc !important;
    width: 100% !important; 
  }
</style>