import { api } from './api';

export const menuService = {
    getCliente,
    getMenuFuncionalidades,
    getMenuFuncionalidadPerfiles,
    postMenuFuncionalidad,
    putMenuFuncionalidadPerfil,
    putMenuFuncionalidad,
};


function getCliente(data) {
  return api.post(
    api.tx.getCliente, 
    {
      'id_cliente':data.id_cliente
    }
  ).then(data => {
    return data;
  });
}

function getMenuFuncionalidades(data) {
  return api.post(
    api.tx.getMenuFuncionalidades, 
    {
      'id_modulo':data.id_modulo,
      'id_padre':data.id_padre,
      'id_funcionalidad':data.id_funcionalidad
    }
  ).then(data => {
    return data;
  });
}

function getMenuFuncionalidadPerfiles(data) {
  return api.post(
    api.tx.getMenuFuncionalidadPerfiles, 
    data
  ).then(data => {
    return data;
  });
}

function postMenuFuncionalidad(data) {
  return api.post(
    api.tx.postMenuFuncionalidad, 
    data
  ).then(data => {
    return data;
  });
}

function putMenuFuncionalidadPerfil(data) {
  return api.post(
    api.tx.putMenuFuncionalidadPerfil, 
    data
  ).then(data => {
    return data;
  });
}

function putMenuFuncionalidad(data) {
  return api.post(
    api.tx.putMenuFuncionalidad, 
    data
  ).then(data => {
    return data;
  });
}