<template>
  <div>
    <b-row class="justify-content-md-end">
      <b-col cols="12" md="auto">
        <slot name="table-actions">
        </slot>
        <br>
      </b-col>
    </b-row>
    <div v-if="!loading" class="table-responsive">
      <table style="width:100%" :class="'table '+clases" :id="id">
        <thead>
        <tr>
          <th v-for="(column,i) in columns" :key="i" :class="column.field=='action'? 'no-exportable':''" >{{ column.label }}</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(row,index) in rows" :key="index" :class="getRowStyleClass(row)">
            <!--<td v-for="(column,i) in columns" :key="i" :class="column.tdClass? column.tdClass:''">-->
            <td v-for="(column,i) in columns" :key="i" :class="getTdStyleClass(row, column) + ' ' +column.tdClass">
              <slot
                name="table-row"
                :row="c_row(row,index)"
                :column="column"
                :formattedRow="formattedRow(row)"
                :index="index">
                <span v-html="collect(row, column.field)"></span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-skeleton-table v-else :columns="columns.length == 0 ? 1 : columns.length"></b-skeleton-table>
  </div>

</template>

<script>


import JSZip from 'jszip';

import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-buttons/js/buttons.html5';

$.fn.dataTable.Buttons.jszip(JSZip);

export default {
  name: "DataTable",
  props: {
    loading: { default: false },
    clases: String,
    rows :{},
    columns : {},
    rowStyleClass:{ default: null, type: [Function, String] },
    tdStyleClass:{ default: null, type: [Function, String] },
    perPage:{default: 50, type: Number},
    buttons:{default: true,type: Boolean},
    searching:{default: true,type: Boolean},
    ordering:{default: true,type: Boolean},
    loadFormat:{default: true,type: Boolean}
  },
  data () {
    return {
      id: null,
    }
  },
  created() {
    this.id = this._uid;
  },
  mounted() {
    if (!this.loading){
      this.cargarDataTable();
    }
  },
  watch:{
    loading: function (loading) {
      if (!loading){
        this.$nextTick(() => {
          this.cargarDataTable();
        });
      }
    },
  },
  methods:{
    cargarDataTable(){
      if(this.loadFormat){
        $("#"+this.id).dataTable({
          "language": { 
            "aria": {
                "sortAscending": ": orden ascendente",
                "sortDescending": ": orden descendente"
            },
            "autoFill": {
                "cancel": "Cancelar",
                "fill": "Llenar todas las celdas con <i>%d&lt;\\\/i&gt;<\/i>",
                "fillHorizontal": "Llenar celdas horizontalmente",
                "fillVertical": "Llenar celdas verticalmente"
            },
            "buttons": {
                "collection": "Colección <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
                "colvis": "Visibilidad de la columna",
                "colvisRestore": "Restaurar visibilidad",
                "copy": "Copiar",
                "copyKeys": "Presiona ctrl or u2318 + C para copiar los datos de la tabla al portapapeles.<br \/><br \/>Para cancelar, haz click en este mensaje o presiona esc.",
                "copySuccess": {
                    "_": "Copió %ds registros al portapapeles",
                    "1": "Copió un registro al portapapeles"
                },
                "copyTitle": "Copiado al portapapeles",
                "csv": "CSV",
                "excel": "Excel",
                "pageLength": {
                    "_": "Mostrar %ds registros",
                    "-1": "Mostrar todos los registros"
                },
                "pdf": "PDF",
                "print": "Imprimir"
            },
            "datetime": {
                "amPm": [
                    "AM",
                    "PM"
                ],
                "hours": "Horas",
                "minutes": "Minutos",
                "months": {
                    "0": "Enero",
                    "1": "Febrero",
                    "10": "Noviembre",
                    "11": "Diciembre",
                    "2": "Marzo",
                    "3": "Abril",
                    "4": "Mayo",
                    "5": "Junio",
                    "6": "Julio",
                    "7": "Agosto",
                    "8": "Septiembre",
                    "9": "Octubre"
                },
                "next": "Siguiente",
                "previous": "Anterior",
                "seconds": "Segundos",
                "weekdays": [
                    "Dom",
                    "Lun",
                    "Mar",
                    "Mie",
                    "Jue",
                    "Vie",
                    "Sab"
                ]
            },
            "decimal": ",",
            "editor": {
                "close": "Cerrar",
                "create": {
                    "button": "Nuevo",
                    "submit": "Crear",
                    "title": "Crear nuevo registro"
                },
                "edit": {
                    "button": "Editar",
                    "submit": "Actualizar",
                    "title": "Editar registro"
                },
                "error": {
                    "system": "Ocurrió un error de sistema (&lt;a target=\"\\\" rel=\"nofollow\" href=\"\\\"&gt;Más información)."
                },
                "multi": {
                    "info": "Los elementos seleccionados contienen diferentes valores para esta entrada. Para editar y configurar todos los elementos de esta entrada con el mismo valor, haga clic o toque aquí, de lo contrario, conservarán sus valores individuales.",
                    "noMulti": "Esta entrada se puede editar individualmente, pero no como parte de un grupo.",
                    "restore": "Deshacer cambios",
                    "title": "Múltiples valores"
                },
                "remove": {
                    "button": "Eliminar",
                    "confirm": {
                        "_": "¿Está seguro de que desea eliminar %d registros?",
                        "1": "¿Está seguro de que desea eliminar 1 registro?"
                    },
                    "submit": "Eliminar",
                    "title": "Eliminar registro"
                }
            },
            "emptyTable": "Sin registros",
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 a 0 de 0 registros",
            "infoFiltered": "(filtrado de _MAX_ registros)",
            "infoThousands": ".",
            "lengthMenu": "Mostrar _MENU_ registros",
            "loadingRecords": "Cargando...",
            "paginate": {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchBuilder": {
                "add": "Agregar Condición",
                "button": {
                    "_": "Filtros (%d)",
                    "0": "Filtrar"
                },
                "clearAll": "Limpiar Todo",
                "condition": "Condición",
                "conditions": {
                    "array": {
                        "contains": "Contiene",
                        "empty": "Vacío",
                        "equals": "Igual",
                        "not": "Distinto",
                        "notEmpty": "No vacío",
                        "without": "Sin"
                    },
                    "date": {
                        "after": "Mayor",
                        "before": "Menor",
                        "between": "Entre",
                        "empty": "Vacío",
                        "equals": "Igual",
                        "not": "Distinto",
                        "notBetween": "No entre",
                        "notEmpty": "No vacío"
                    },
                    "number": {
                        "between": "Entre",
                        "empty": "Vacío",
                        "equals": "Igual",
                        "gt": "Mayor",
                        "gte": "Mayor o igual",
                        "lt": "Menor",
                        "lte": "Menor o igual",
                        "not": "Distinto",
                        "notBetween": "No entre",
                        "notEmpty": "No vacío"
                    },
                    "string": {
                        "contains": "Contiene",
                        "empty": "Vacío",
                        "endsWith": "Termina con",
                        "equals": "Igual",
                        "not": "Distinto",
                        "notEmpty": "No vacío",
                        "startsWith": "Comienza con"
                    }
                },
                "data": "Datos",
                "deleteTitle": "Eliminar regla de filtrado",
                "leftTitle": "Filtros anulados",
                "logicAnd": "Y",
                "logicOr": "O",
                "rightTitle": "Filtro",
                "title": {
                    "_": "Filtros (%d)",
                    "0": "Filtrar"
                },
                "value": "Valor"
            },
            "searchPanes": {
                "clearMessage": "Limpiar todo",
                "collapse": {
                    "_": "Paneles de búsqueda (%d)",
                    "0": "Paneles de búsqueda"
                },
                "count": "{total}",
                "countFiltered": "{shown} ({total})",
                "emptyPanes": "Sin paneles de búsqueda",
                "loadMessage": "Cargando paneles de búsqueda...",
                "title": "Filtros activos - %d"
            },
            "select": {
                "cells": {
                    "_": "%d celdas seleccionadas",
                    "1": "Una celda seleccionada"
                },
                "columns": {
                    "_": "%d columnas seleccionadas",
                    "1": "Una columna seleccionada"
                },
                "rows": {
                    "1": "Una fila seleccionada",
                    "_": "%d filas seleccionadas"
                }
            },
            "thousands": ".",
            "zeroRecords": "No se encontraron registros"
          },
          dom: 'lBfrtip',
          order: [],
          destroy: true,
          searching: this.searching,
          ordering: this.ordering,
          iDisplayLength: this.perPage,
          //responsive:true,
          buttons: this.buttons ? [
            {
              extend          : "pdfHtml5",
              exportOptions   : {
                columns: 'th:not(.no-exportable)'
              }
            },
            {
              extend          : "copyHtml5",
              exportOptions   : {
                columns: 'th:not(.no-exportable)'
              }
            },
            {
              extend          : "excelHtml5",
              exportOptions   : {
                columns: 'th:not(.no-exportable)'
              }
            },
            {
              extend          : "csvHtml5",
              exportOptions   : {
                columns: 'th:not(.no-exportable)'
              }
            },
          ] : []
        });
      }
    },
    c_row(row,index){
      let newRow = row;
      newRow['originalIndex'] = index;
      return newRow;
    },
    getRowStyleClass(row) {
      let classes = '';
      let rowStyleClasses;
      if (typeof this.rowStyleClass === 'function') {
        rowStyleClasses = this.rowStyleClass(row);
      } else {
        rowStyleClasses = this.rowStyleClass;
      }
      if (rowStyleClasses) {
        classes += ` ${rowStyleClasses}`;
      }
      return classes;
    },
    getTdStyleClass(row, column) {
      let classes = '';
      let tdStyleClasses;
      if (typeof this.tdStyleClass === 'function') {
        tdStyleClasses = this.tdStyleClass(row, column);
      } else {
        tdStyleClasses = this.tdStyleClass;
      }
      if (tdStyleClasses) {
        classes += ` ${tdStyleClasses}`;
      }
      return classes;
    },
    collect(obj, field) {
      // utility function to get nested property
      function dig(obj, selector) {
        let result = obj;
        const splitter = selector.split('.');
        for (let i = 0; i < splitter.length; i++) {
          if (typeof result === 'undefined' || result === null) {
            return undefined;
          }
          result = result[splitter[i]];
        }
        return result;
      }

      if (typeof field === 'function') return field(obj);
      if (typeof field === 'string') return dig(obj, field);
      return undefined;
    },
    formattedRow(row) {
      const formattedRow = {};
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i];
        // what happens if field is
        if (col.field) {
          formattedRow[col.field] = row[col.field];
        }
      }
      return formattedRow;
    },
  }

}
</script>

<style scoped>
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: inherit;
  }

  .table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
    background-color: inherit;
  }

  .table-responsive >>> .dt-length {
    float: left;
  }

  .table-responsive >>> select {
    display: inherit !important;
    width: inherit !important;
  }

  .table-responsive >>> .dt-search {
    float: right;
  }

  .table-responsive >>> input {
    display: inline !important;
    width: inherit !important;
  }

  .table-responsive >>> .dt-paging {
    float: right;
  }

  .table-responsive >>> .dt-column-order {
    position: relative;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    float: right;
  }

  .table-responsive >>> .dt-orderable-asc >.dt-column-order:before {
    float: right;
    bottom: 50%;
    content: "▲";
    content: "▲"/"";
    opacity: 0.4;
    line-height: 9px;
    font-size: .8em;
    position: absolute;
  }

  .table-responsive >>> .dt-ordering-asc > .dt-column-order:before {
    opacity: 1.0;
  }

  .table-responsive >>> .dt-orderable-desc > .dt-column-order:after {
    float: right;
    top: 50%;
    content: "▼";
    content: "▼"/"";
    opacity: 0.4;
    line-height: 9px;
    font-size: .8em;
    position: absolute;
  }

  .table-responsive >>> .dt-ordering-desc > .dt-column-order:after {
    opacity: 1.0;
  }
</style>