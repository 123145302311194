<template>
  <div style="width:100%" :align="align">
      {{text}}
      <b-icon icon="arrow-repeat" animation="spin" :font-scale="size">
      </b-icon>
  </div>
</template>

<script>

export default {
  name: "Loading",
  props:{'text':{}, 'align': {}, 'size': {
    default: 2
  }}
};
</script>
