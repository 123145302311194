import { api } from './api';

export const clienteService = {
    getCliente,
    postCliente,
    putCliente,
    activarCliente
};

function postCliente(data) {
  return api.post(
    api.tx.postCliente, 
    data
  ).then(data => {
    return data;
  });
}

function putCliente(data) {
  return api.post(
    api.tx.putCliente, 
    data
  ).then(data => {
    return data;
  });
}

function getCliente(data) {
  return api.post(
    api.tx.getCliente, 
    data
  ).then(data => {
    return data;
  });
}

function activarCliente(data) {
    return api.post(
        api.tx.activarCliente,
        data
    ).then(data => {
      return data;
    });
}