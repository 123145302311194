<template>
  <div v-if="user != null" id="div_app" :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}]">
    <TopBar/>
    <b-col >
      <router-view v-if="user.id_cliente != -1"></router-view>
      <b-alert v-else show variant="danger">No hay mano, selecciona cliente</b-alert>
    </b-col>
    <sidebar-menu
      :width="'300px'"
      :collapsed="collapsed" 
      :menu="menu"
      :showOneChild="true"
      @toggle-collapse="onToggleCollapse"
      :theme="'white-theme'">
      <span slot="toggle-icon" v-if="collapsed">
        <b-icon scale="1.5" icon="arrow-right"/>
      </span>
      <span slot="toggle-icon" v-else>
        <b-icon scale="1.5" icon="arrow-left"/>
      </span>
      <div slot="header" v-if="!collapsed">
        <b-col style="padding-top:11px; padding-bottom:10px; background-color:white; border-bottom: 4px solid #e7eaec">
          <div>
              <img width="150" src="../assets/logo.png">
          </div>
        </b-col>
      </div>
    </sidebar-menu>
  </div>
</template>
<script>

import {userService, azemiService} from "../services";

export default {
  mounted () {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.getData();
  },
  data() {
    return {
      user : userService.getUser(),
      is_loading: true,
      collapsed: false,
      isOnMobile: false,
      menu: [
        {
          'title':'Cargando menu'
        }
      ]
    }
  },
  methods: {
    getData(){
      this.is_loading = true;
      azemiService.getMenu().then(data => {
        if(data.status == 'success'){
          this.menu = data.data;
        }else{
          this.menu = [{'title':data.msg}]
        }
        this.is_loading = false;
      });
    },
    handleLogout(){
      userService.logout();
      location.reload(true);
    },
    onToggleCollapse (collapsed) {
      this.collapsed = collapsed
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    }
  },
}
</script>

<style>
  @import '../assets/style.css';

  #div_app {
    padding-left: 300px;
    transition: 0.3s ease;
  }
  #div_app.collapsed {
    padding-left: 50px;
  }
  #div_app.onmobile {
    padding-left: 50px;
  }

  .v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
    background-color: white !important;
  }

  /*
  .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
    background-color: #4285f4 !important;
  }
  */

  .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active .vsm--icon, .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
    color: black !important;
  }

  .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 {
    background-color: #169dd9 !important;
  }

  .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
    background-color: #169dd9 !important;
  }

</style>
