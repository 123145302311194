import { api } from './api';

export const widgetService = {
  getWidgetInicio,
  postWidgetInicio,
  getWidgets
};

function getWidgets(data) {
  return api.post(
    api.tx.getWidgets, 
    data
  ).then(data => {
    return data;
  });
}

function getWidgetInicio(data) {
  return api.post(
    api.tx.getWidgetInicio, 
    data
  ).then(data => {
    return data;
  });
}

function postWidgetInicio(data) {
  return api.post(
    api.tx.postWidgetInicio, 
    data
  ).then(data => {
    return data;
  });
}