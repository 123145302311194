import { api } from './api';

var sha1 = require('js-sha1');

export const userService = {
  verificarCorreoUniqueId,
  putNuevaPass,
  recuperarPassUsuario,
  getUser,
  getUsuario,
  getUsuarios,
  getConfiguracion,
  postUsuario,
  putUsuario,
  putPassUsuario,
  login,
  logout,
  setCliente,
  getUsuariosAutoAprob,
  putUsuariosAutoAprob
};

function verificarCorreoUniqueId(data){
  return api.post(
    api.tx.verificarCorreoUniqueId, 
    data
  ).then(data => {
    return data;
  });
}

function putNuevaPass(data){
  return api.post(
    api.tx.putNuevaPass, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuario(){
  return api.post(
    api.tx.getUsuario, 
    {
      id_usuario:getUser().id_usuario,
      t:userService.getConfiguracion().t
    }
  ).then(data => {
    if(data.status == "success"){
      localStorage.setItem('user', JSON.stringify(data.data));
    }
    return data;
  });
}

function recuperarPassUsuario(correo){
  return api.post(
    api.tx.recuparPassUsuario, 
    {
      'correo':correo,
    }
  ).then(data => {
    return data;
  });
}

function getUsuarios(data){
  return api.post(
    api.tx.getUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuariosAutoAprob(data){
  return api.post(
    api.tx.getUsuariosAutoAprob,
    data
  ).then(data => {
    return data;
  });
}

function putUsuariosAutoAprob(data){
  return api.post(
    api.tx.putUsuariosAutoAprob,
    data
  ).then(data => {
    return data;
  });
}

function postUsuario(data){
  return api.post(
    api.tx.postUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuario(data){
  return api.post(
    api.tx.putUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putPassUsuario(usuario){
  return api.post(
    api.tx.putPassUsuario, 
    {
      'id_usuario':usuario.id_usuario,
      'pass': sha1(usuario.pass),
    }
  ).then(data => {
    return data;
  });
}

function getUser(){
  let user = JSON.parse(localStorage.getItem('user'));
  return user;
}

function getConfiguracion(){
  return JSON.parse(getUser().configuracion);
}

function setCliente(id_cliente){
  let user = getUser();
  user.id_cliente = id_cliente;
  localStorage.setItem('user', JSON.stringify(user));
}

function login(correo, pass) {
  pass = sha1(pass);

  return api.post(
    api.tx.login, 
    {
      correo,
      pass
    }
  ).then(data => {
    if(data.status == "success"){
      localStorage.setItem('user', JSON.stringify(data.data));
    }
    return data;
  });
}

function logout() {
  localStorage.removeItem('user');
}