<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="ibox float-e-margins">
          <div v-if="titulo" class="ibox-title">
            <b-button style="margin-right:10px;" variant="outline-primary" size="sm" v-if="back" @click="$router.go(-1)">
              <b-icon icon="arrow-left"></b-icon> Volver
            </b-button>
            <h5 style="float:inherit">{{ titulo }}</h5>
          </div>
          <div class="ibox-content">
            <b-row>
              <slot></slot>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Ibox', 
  props: ['titulo','back']
}
</script>

<style scoped>
  .ibox {
    margin-bottom:15px
  }
</style>
