<template>
  <b-col :cols=cols>
    <b-form-group
      :label="label">
      <slot v-if="!is_loading"></slot>
      <b-skeleton type="input" v-else width="100%"></b-skeleton>
    </b-form-group>	
  </b-col>
</template>

<script>

export default {
  name: "FormGroup",
  props:['cols','label','is_loading']
};
</script>
