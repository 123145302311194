<template>
  <b-navbar toggleable="lg" style="background-color:white;">
    <h2 style="color:#169dd9">{{ titulo }} </h2>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="mostrarDropdown()" class="ml-auto" style="margin-bottom:-5px">
        <b-nav-item-dropdown right :text=nombre_cliente_actual>
          <b-dropdown-item v-on:click="setCliente(cliente)" v-for="cliente in clientes" :key=cliente.id_cliente>
            {{ cliente.nombre }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <h4 style="color:#169dd9">{{ user.nombre_usuario }}</h4>
  </b-navbar>
</template>

<script>

import {clienteService} from "../services";
import {userService} from "../services";

export default {
  name: "TopBar",
  data() {
    return {
      user : userService.getUser(),
      clientes: [],
      nombre_cliente_actual: "Seleccione cliente",
      titulo:document.title
    };
  },
  mounted(){
    window.addEventListener('page-title-changed', (event) => {
      this.titulo = event.detail.titulo;
    });
  },
  created(){
    this.getData()
  },
  methods: {
    mostrarDropdown(){
      if(userService.getUser().perfiles.includes(1)){
        return true;
      }
      return false;
    },
    setCliente(cliente){
      userService.setCliente(cliente.id_cliente);
      this.nombre_cliente_actual = cliente.nombre;

      location.reload();
    },
    getData(){
      clienteService.getCliente({
        id_cliente:''
      }).then(data => {
        this.clientes = data.data;

        if(data.status == 'success'){
          this.clientes.forEach(cliente => {
            if(cliente.id_cliente == userService.getUser().id_cliente){
              this.nombre_cliente_actual = cliente.nombre;
            }
          });
        }
      });
    }
  },
};
</script>

<style scoped>
  h2 {
    font-weight:400;
    margin:0;
    margin-left:15px;
    margin-top:5px; 
    color: #424242;
    font-family: Roboto,sans-serif;
    text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
    font-size:22px
  }

  h4 {
    font-weight: 200;
    margin:0;
    margin-left:15px;
    margin-right: 20px;
    margin-top:5px; 
    font-family: Roboto,sans-serif;
    font-size:14px
  }
  
</style>
