<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App', 
  data(){
    return {
      style: {
        'height':window.innerHeight+'px',
        'background-color':'white'
      }
    }
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  methods:{
    onResize () {
      this.style = {
        'height':window.innerHeight+'px',
        'background-color':'white'
      }
    }
  }
}
</script>

<style>
</style>

