import {api} from './api';
import {userService, globales} from "@/services";

export const trabajadorService = {
    getTipoTrabajador,
    getTrabajador,
    getTrabajadores,
    postTrabajador,
    putTrabajador,
    getDocumentoTrabajadores,
    getDocBitacoraTrabajador,
    deleteDocumentoTrabajador,
    putDocumentoTrabajador,
    getDocumentosHistoricosTrabajador,
    getConfiguracionDocumentosTrabajador,
    postCategoriaDocumentoTrabajador,
    putCategoriaDocumentoTrabajador,
    postTipoDocumentoTrabajador,
    putTipoDocumentoTrabajador,
    getDocumentosDescargaTrabajador,
    getCategoriaDocumentoTrabajador,
    getTipoDocumentoTrabajador,
    downloadDocumentosTrabajador,
    postTipoTrabajador,
    putTipoTrabajador,
    getVencimientoDocumentosTrabajador,
    getCamposTrabajador,
    getDocumentosTrabajadorFaena,
    getDocumentos,
    getVencimientos,
    getDocumentosDescarga,
    postCamposTrabajador,
    getTrabajadorResumenGlobalDocumentos,
    getTrabajadorResumenDocumentos,
    getIndicadoresTrabajador2,
    getTrabajadorBitacora,
    putDocumentoTrabajadorFechaVencimiento,
    deleteTrabajador
};

function deleteTrabajador(data) {
  return api.post(
    api.tx.deleteTrabajador,
    data
  ).then(data => {
    return data;
  });
}

function putDocumentoTrabajadorFechaVencimiento(data) {
  return api.post(
    api.tx.putDocumentoTrabajadorFechaVencimiento,
    data
  ).then(data => {
    return data;
  });
}

function getTrabajadorBitacora(data) {
  return api.post(
    api.tx.getTrabajadorBitacora,
    data
  ).then(data => {
    return data;
  });
}

function getIndicadoresTrabajador2(data) {
  return api.post(
    api.tx.getIndicadoresTrabajador2,
    data
  ).then(data => {
    return data;
  });
}

function getTrabajadorResumenDocumentos(data){
  return api.post(
      api.tx.getTrabajadorResumenDocumentos,
      data
  ).then(data => {
    return data;
  });
}

function getTrabajadorResumenGlobalDocumentos(data){
  return api.post(
      api.tx.getTrabajadorResumenGlobalDocumentos,
      data
  ).then(data => {
    return data;
  });
}

function getTrabajadores(data){
  return api.post(
      api.tx.getTrabajadores,
      data
  ).then(data => {
    return data;
  });
}

function postCamposTrabajador(data){
  return api.post(
      api.tx.postCamposTrabajador,
      data
  ).then(data => {
    return data;
  });
}

function getDocumentosDescarga(data){
  let tx;

  if(userService.getConfiguracion().usa_faena == 'S' && 
      userService.getUser().perfiles.includes(globales.perfil.supervisor_faena) &&
      userService.getUser().perfiles.length == 1){
    tx = api.tx.getDocumentosDescargaTrabajadorFaena;
  }else{
    tx = api.tx.getDocumentosDescargaTrabajador;
  }

  return api.post(
      tx,
      data
  ).then(data => {
    return data;
  });
}

function getVencimientos(data){
  let tx;

  if(userService.getConfiguracion().usa_faena == 'S' && 
      userService.getUser().perfiles.includes(globales.perfil.supervisor_faena) &&
      userService.getUser().perfiles.length == 1){
    tx = api.tx.getVencimientoDocumentosTrabajadorFaena;
  }else{
    tx = api.tx.getVencimientoDocumentosTrabajador;
  }

  return api.post(
      tx,
      data
  ).then(data => {
    return data;
  });
}

function getDocumentos(data){
  let tx;
  
  if(userService.getConfiguracion().usa_faena == 'S' && 
      userService.getUser().perfiles.includes(globales.perfil.supervisor_faena) &&
      userService.getUser().perfiles.length == 1){
    tx = api.tx.getDocumentosTrabajadorFaena;
  }else{
    tx = api.tx.getDocumentoTrabajadores;
  }

  return api.post(
      tx,
      data
  ).then(data => {
    return data;
  });
}

function getDocumentosTrabajadorFaena(data) {
  return api.post(
      api.tx.getCamposTrabajador,
      data
  ).then(data => {
    return data;
  });
}

function getCamposTrabajador(data) {
  return api.post(
      api.tx.getCamposTrabajador,
      data
  ).then(data => {
    return data;
  });
}

function postTipoTrabajador(data) {
    return api.post(
        api.tx.postTipoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function putTipoTrabajador(data) {
    return api.post(
        api.tx.putTipoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getCategoriaDocumentoTrabajador(data) {
    return api.post(
        api.tx.getCategoriaDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getTipoDocumentoTrabajador(data) {
    return api.post(
        api.tx.getTipoDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getDocumentosDescargaTrabajador(data) {
    return api.post(
        api.tx.getDocumentosDescargaTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function downloadDocumentosTrabajador(data) {
    return api.post(
        api.tx.downloadDocumentosTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function postCategoriaDocumentoTrabajador(data) {
    return api.post(
        api.tx.postCategoriaDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function putCategoriaDocumentoTrabajador(data) {
    return api.post(
        api.tx.putCategoriaDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function postTipoDocumentoTrabajador(data) {
    return api.post(
        api.tx.postTipoDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function putTipoDocumentoTrabajador(data) {
    return api.post(
        api.tx.putTipoDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getConfiguracionDocumentosTrabajador(data) {
    return api.post(
        api.tx.getConfiguracionDocumentosTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getDocumentosHistoricosTrabajador(data) {
    return api.post(
        api.tx.getDocumentosHistoricosTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function putDocumentoTrabajador(data) {
    return api.post(
        api.tx.putDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function deleteDocumentoTrabajador(data) {
    return api.post(
        api.tx.deleteDocumentoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getDocBitacoraTrabajador(data) {
    return api.post(
        api.tx.getDocBitacoraTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getDocumentoTrabajadores(data) {
    return api.post(
        api.tx.getDocumentoTrabajadores,
        data
    ).then(data => {
      return data;
    });
}

function postTrabajador(data) {
    return api.post(
        api.tx.postTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function putTrabajador(data) {
    return api.post(
        api.tx.putTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getTipoTrabajador(data) {
    return api.post(
        api.tx.getTipoTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getTrabajador(data) {
    return api.post(
        api.tx.getTrabajador,
        data
    ).then(data => {
      return data;
    });
}

function getVencimientoDocumentosTrabajador(data) {
    return api.post(
        api.tx.getVencimientoDocumentosTrabajador,
        data
    ).then(data => {
      return data;
    });
}