import { api } from './api';

export const sucursalService = {
  getSucursal,
  postSucursal,
  putSucursal,
  getUsuariosSucursal,
  putUsuarioSucursal,
  getTrabajadoresSucursal,
  putTrabajadorSucursal,
  putTrabajadoresSucursal,
  getEmpresasSucursal,
  putEmpresaSucursal,
  putEmpresasSucursal,
  getSucursalEmpresasAsociadas,
  getVehiculosSucursal,
  putVehiculoSucursal,
  putVehiculosSucursal,
};

function getVehiculosSucursal(data) {
  return api.post(
    api.tx.getVehiculosSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putVehiculoSucursal(data) {
  return api.post(
    api.tx.putVehiculoSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putVehiculosSucursal(data) {
  return api.post(
    api.tx.putVehiculosSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursalEmpresasAsociadas(data) {
  return api.post(
    api.tx.getSucursalEmpresasAsociadas, 
    data
  ).then(data => {
    return data;
  });
}

function getEmpresasSucursal(data) {
  return api.post(
    api.tx.getEmpresasSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putEmpresaSucursal(data) {
  return api.post(
    api.tx.putEmpresaSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putEmpresasSucursal(data) {
  return api.post(
    api.tx.putEmpresasSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getTrabajadoresSucursal(data) {
  return api.post(
    api.tx.getTrabajadoresSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putTrabajadorSucursal(data) {
  return api.post(
    api.tx.putTrabajadorSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putTrabajadoresSucursal(data) {
  return api.post(
    api.tx.putTrabajadoresSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuariosSucursal(data) {
  return api.post(
    api.tx.getUsuariosSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuarioSucursal(data) {
  return api.post(
    api.tx.putUsuarioSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursal(data) {
  return api.post(
    api.tx.getSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function postSucursal(data) {
  return api.post(
    api.tx.postSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putSucursal(data) {
  return api.post(
    api.tx.putSucursal,
    data
  ).then(data => {
    return data;
  });
}