import {api} from "@/services/api";

export const vehiculoService = {
  getTipoVehiculos,
  putTipoVehiculo,
  postTipoVehiculo,
  getVehiculos,
  putVehiculo,
  getMarca,
  postVehiculo,
  getDocumentosVehiculo,
  getDocBitacoraVehiculo,
  deleteDocumentoVehiculo,
  putDocumentoVehiculo,
  getDocumentosHistoricosVehiculo,
  getConfiguracionDocumentosVehiculo,
  postCategoriaDocumentoVehiculo,
  putCategoriaDocumentoVehiculo,
  getCategoriaDocumentoVehiculo,
  postTipoDocumentoVehiculo,
  getTipoDocumentoVehiculo,
  putTipoDocumentoVehiculo,
  getDocumentosDescargaVehiculo,
  downloadDocumentosVehiculo,
  getVencimientoDocumentosVehiculo,
  postMarcaVehiculo,
  getVehiculoResumenDocumentos,
  getIndicadoresVehiculo2,
  putDocumentoVehiculoFechaVencimiento
};

function putDocumentoVehiculoFechaVencimiento(data) {
  return api.post(
      api.tx.putDocumentoVehiculoFechaVencimiento,
      data
  ).then(data => {
    return data;
  });
}

function getIndicadoresVehiculo2(data) {
  return api.post(
      api.tx.getIndicadoresVehiculo2,
      data
  ).then(data => {
    return data;
  });
}

function getVehiculoResumenDocumentos(data) {
  return api.post(
      api.tx.getVehiculoResumenDocumentos,
      data
  ).then(data => {
    return data;
  });
}

function postMarcaVehiculo(data) {
  return api.post(
      api.tx.postMarcaVehiculo,
      data
  ).then(data => {
    return data;
  });
}

function getTipoVehiculos(data) {
    return api.post(
        api.tx.getTipoVehiculos,
        data
    ).then(data => {
      return data;
    });
}

function putTipoVehiculo(data) {
    return api.post(
        api.tx.putTipoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function postTipoVehiculo(data) {
    return api.post(
        api.tx.postTipoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getVehiculos(data) {
    return api.post(
        api.tx.getVehiculos,
        data
    ).then(data => {
      return data;
    });
}
function putVehiculo(data) {
    return api.post(
        api.tx.putVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getMarca(data) {
    return api.post(
        api.tx.getMarcaVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function postVehiculo(data) {
    return api.post(
        api.tx.postVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getDocumentosVehiculo(data) {
    return api.post(
        api.tx.getDocumentosVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getDocBitacoraVehiculo(data) {
    return api.post(
        api.tx.getDocBitacoraVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function deleteDocumentoVehiculo(data) {
    return api.post(
        api.tx.deleteDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function putDocumentoVehiculo(data) {
    return api.post(
        api.tx.putDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getDocumentosHistoricosVehiculo(data) {
    return api.post(
        api.tx.getDocumentosHistoricosVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getConfiguracionDocumentosVehiculo(data) {
    return api.post(
        api.tx.getConfiguracionDocumentosVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function postCategoriaDocumentoVehiculo(data) {
    return api.post(
        api.tx.postCategoriaDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function putCategoriaDocumentoVehiculo(data) {
    return api.post(
        api.tx.putCategoriaDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getCategoriaDocumentoVehiculo(data) {
    return api.post(
        api.tx.getCategoriaDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function postTipoDocumentoVehiculo(data) {
    return api.post(
        api.tx.postTipoDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getTipoDocumentoVehiculo(data) {
    return api.post(
        api.tx.getTipoDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function putTipoDocumentoVehiculo(data) {
    return api.post(
        api.tx.putTipoDocumentoVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getDocumentosDescargaVehiculo(data) {
    return api.post(
        api.tx.getDocumentosDescargaVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function downloadDocumentosVehiculo(data) {
    return api.post(
        api.tx.downloadDocumentosVehiculo,
        data
    ).then(data => {
      return data;
    });
}
function getVencimientoDocumentosVehiculo(data) {
    return api.post(
        api.tx.getVencimientoDocumentosVehiculo,
        data
    ).then(data => {
      return data;
    });
}
