import {api} from "@/services/api";

export const tareaService = {
    getTarea,
    getCategoriaTarea,
    getUsuariosTarea,
    postTarea,
    postCategoria,
    putCategoria,
    putUsers,
    getBitacora,
    putTarea,
    postArchivo,
    getArchivoTarea,
    getPendientePorCategoriaTarea
};
function getPendientePorCategoriaTarea(data) {
  return api.post(
      api.tx.getPendientePorCategoriaTarea,
      data
  ).then(data => {
    return data;
  });
}

function getTarea(data) {
    return api.post(
        api.tx.getTarea,
        data
    ).then(data => {
      return data;
    });
}

function getCategoriaTarea(data) {
    return api.post(
        api.tx.getCategoriaTarea,
        data
    ).then(data => {
      return data;
    });
}

function getUsuariosTarea(data) {
    return api.post(
        api.tx.getUsuariosTarea,
        data
    ).then(data => {
      return data;
    });
}

function postTarea(data){
    return api.post(
        api.tx.postTarea,
        data
    ).then(data => {
      return data;
    });
}

function postCategoria(data){
    return api.post(
        api.tx.postCategoriaTarea,
        data
    ).then(data => {
      return data;
    });
}

function putCategoria(categoria){
    return api.post(
        api.tx.putCategoriaTarea,
        categoria
    ).then(data => {
      return data;
    });
}

function putUsers(data){
    return api.post(
        api.tx.putUsuarioTarea,
        data
    ).then(data => {
      return data;
    });
}

function getBitacora(id_tarea){
    return api.post(
        api.tx.getBitacoraTarea,
        {
            'id_tarea':id_tarea,
        }
    ).then(data => {
      return data;
    });
}

function putTarea(data){
    return api.post(
        api.tx.putTarea,
        data
    ).then(data => {
      return data;
    });
}
function postArchivo(data){
    return api.post(
        api.tx.postArchivoTarea,
        data
    ).then(data => {
      return data;
    });
}
function getArchivoTarea(data){
    return api.post(
        api.tx.getArchivoTarea,
        data
    ).then(data => {
      return data;
    });
}