import AES from 'crypto-js/aes'
import encHex from 'crypto-js/enc-hex'

import VueSimpleAlert from "vue-simple-alert"
import {userService} from "@/services"

import dateformat, { masks } from "dateformat";
import { config } from "../services/config";

function encrypt(data){
  if(config.env == 'production'){
    let key = encHex.parse("4a7e123d71b24e70616b42aa228fbb6c");
    let iv =  encHex.parse("f2a183b31d6c4278a31e1f9b73cb9d1a");

    data = AES.encrypt(data, key, {iv:iv}).toString();
  }

  return data;
}

function txtBtnCargar(estado){
  if(estado == 'A' || estado == 'R' || estado == 'V'){
    return "Reemplazar";
  }else{
    return "Buscar";
  }
}

//lo paso a json(string) y luego a base64 encode
//objeto => string
function je64(data){
  return btoa(JSON.stringify(data));
}

//lo paso de bse64 a json(string), luego a objeto
//string => objeto
function jd64(data){
  return JSON.parse(atob(data));
}

function alerta(data){
  if(data.status == 'danger'){
    data.status = 'error';
  }
  VueSimpleAlert.alert(data.msg,'',data.status);
}

function regla3(total, no_total){
  if(total == 0){
    return 0;
  }
  return (no_total*100/total).toFixed(1);
}

function pintaColor(valor){
  if(valor < 41){
    return "red-bg text-light";
  }
  if(valor < 81){
    return "yellow-bg text-light";
  }
  if(valor > 0){
    return "navy-bg text-light";
  }
  return "";
}

function tienePermiso(){
  if(userService.getUser().permiso == "1"){
    return true;
  }
  return false;
}

function mostrarFiltroEmpresa(){
  if(userService.getUser().id_empresa == 0){
    return true;
  }
  return false;
}

function mostrarFiltroSucursal(){
  if(userService.getUser().id_empresa == 0){
    return true;
  }
  return false;
}

function formatearRut(rut) {
  if (!rut) return '';

  // Elimina caracteres no numéricos y el guion, si existe
  rut = rut.replace(/[^0-9kK]+/g, '').toUpperCase();

  const rutBody = rut.slice(0, -1);
  const rutDv = rut.slice(-1);
  let rutFormateado = '';

  // Agrega los puntos de mil y guion antes del dígito verificador
  for (let i = rutBody.length - 1, j = 0; i >= 0; i--, j++) {
    rutFormateado = rutBody[i] + rutFormateado;
    if (j % 3 === 2 && i !== 0) {
      rutFormateado = '.' + rutFormateado;
    }
  }

  return rutFormateado + '-' + rutDv;
}

function DateToFecha(date, formato){
  masks.fecha = formato;
  return dateformat(date, "fecha");
}

function fechaToDate(date){
  let fecha = date.split("-");
  return new Date(fecha[2], fecha[1]-1, fecha[0]);
}

function importFecha(import_date){
  let fecha = import_date.split("-");
  return new Date(fecha[0], fecha[1]-1, fecha[2]);
}

export const utils = {
  pintaColor,
  alerta,
  mostrarFiltroEmpresa,
  mostrarFiltroSucursal,
  tienePermiso,
  regla3,
  txtBtnCargar,
  formatearRut,
  DateToFecha,
  importFecha,
  jd64,
  je64,
  encrypt,
  fechaToDate
}
