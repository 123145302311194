<template>
  <div>
    <b-row class="justify-content-md-end">
      <b-col cols="12" md="auto">
        <slot name="list-actions">
        </slot>
        <br>
      </b-col>
    </b-row>
    <div v-if="!loading">
      <div v-if="rows.length" >
        <b-list-group v-for="(item,index) in rows" :key="index">
          <b-list-group-item @mouseover="HoverEnterCat(item.id_categoria)" @mouseleave="HoverLeaveCat(item.id_categoria)" class="flex-column align-items-start mb-1 bg-light" v-if="index === 0||rows[index-1].id_categoria !== item.id_categoria" >
            <b-row>
              <b-col lg="4" :class="isHoveredCat[item.id_categoria] ? 'font-weight-bold m-0' : 'm-0'">
                <i v-if="TieneDoc(item.id_categoria)" :class="toggleOffCat[item.id_categoria] ? 'fas fa-plus handle icono' :'fas fa-minus handle icono'" v-on:click="toggleOffCat[item.id_categoria] ? ToggleOn(item.id_categoria) : ToggleOff(item.id_categoria)"></i>
                {{item.nombre_categoria}}
              </b-col>
              <b-col lg="4">
                <b-badge style="margin-right: 5px" :variant="HabilitadoColor(item.habilitado_cat)">{{Habilitado(item.habilitado_cat)}}</b-badge>
              </b-col>
              <b-col lg="4" class="d-flex flex-row-reverse">
                <slot
                    name="list-row-cat"
                    :row="item"
                    :index="index">
                </slot>
              </b-col>
            </b-row>
          </b-list-group-item>
          <div style="margin-left: 40px;" >
            <b-list-group-item v-show="!toggleOffCat[item.id_categoria]" @mouseover="HoverEnterDoc(item.id_tipo)" @mouseleave="HoverLeaveDoc(item.id_tipo)" class="flex-column align-items-start mb-1 bg-light" v-if="item.id_tipo" >
              <b-row >
                <b-col lg="4" :class="isHoveredDoc[item.id_tipo] ? 'font-weight-bold m-0' : 'm-0'">{{item.nombre_tipo}}</b-col>
                <b-col lg="4">
                  <b-badge style="margin-right: 5px" :variant="HabilitadoColor(item.habilitado_doc)" >{{Habilitado(item.habilitado_doc)}}</b-badge>
                  <b-badge style="margin-right: 5px" :variant="TieneFechaColor(item.tiene_fecha_vencimiento)">{{TieneFecha(item.tiene_fecha_vencimiento)}}</b-badge>
                </b-col>
                <b-col lg="4" class="d-flex flex-row-reverse">
                  <slot
                      name="list-row-doc"
                      :row="item"
                      :index="index">
                  </slot>
                </b-col>
              </b-row>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
      <div v-else class="text-center">
        <h4> Segun el filtro seleccionado no existen registros para mostrar</h4>
      </div>
    </div>
    <b-skeleton-table v-else :columns="1"></b-skeleton-table>



  </div>
</template>

<script>
export default {
  name: "ListGroup",
  props: {
    loading: { default: false },
    rows :{},
  },
  data() {
    return {
      isHoveredDoc: [],
      isHoveredCat: [],
      toggleOffCat:[]
    }
  },
  methods:{
    HoverEnterDoc(id_tipo){
      this.$set(this.isHoveredDoc, id_tipo, true)
    },
    HoverLeaveDoc(id_tipo){
      this.$set(this.isHoveredDoc, id_tipo, false)
    },
    HoverEnterCat(id_cat){
      this.$set(this.isHoveredCat, id_cat, true)
    },
    HoverLeaveCat(id_cat){
      this.$set(this.isHoveredCat, id_cat, false)
    },
    ToggleOff(id_cat){
      this.$set(this.toggleOffCat, id_cat, true)
    },
    ToggleOn(id_cat){
      this.$set(this.toggleOffCat, id_cat, false)
    },
    Habilitado(habilitado){
      if(habilitado == 'S'){
        return 'Habilitado'
      }else{
        return 'Deshabilitado'
      }

    },
    HabilitadoColor(habilitado){
      if(habilitado == 'S'){
        return 'info'
      }else{
        return 'danger'
      }

    },
    TieneFecha(tiene_fecha){
      if(tiene_fecha == 'Si'){
        return 'Con fecha vencimiento'
      }else{
        return 'Sin fecha vencimiento'
      }
    },
    TieneFechaColor(tiene_fecha){
      if(tiene_fecha == 'Si'){
        return 'info'
      }else{
        return 'danger'
      }
    },
    TieneDoc(id_cat){
      const counts = {};
      for (const fila of this.rows) {
        counts[fila.id_categoria] = counts[fila.id_categoria] ? counts[fila.id_categoria] + 1 : 1;
      }
      return (counts[id_cat]>1 ? true : false);
    }

  }
}
</script>

<style scoped>
.icono {
  margin-right: 20px;
}
.handle {
  float: left;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>