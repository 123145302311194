import { api } from './api';
import { userService } from './user.services.js';

export const empresaService = {
  getIndicadoresEmpresa2,
  getEmpresa,
  postEmpresa,
  putEmpresa,
  putDocumentoEmpresa,
  deleteDocumentoEmpresa,
  getDocumentosEmpresa,
  getDocBitacoraEmpresa,
  getCategoriaDocumentoEmpresa,
  getTipoDocumentoEmpresa,
  getDocumentosHistoricosEmpresa,
  getConfiguracionDocumentosEmpresa,
  postCategoriaDocumentoEmpresa,
  putCategoriaDocumentoEmpresa,
  postTipoDocumentoEmpresa,
  putTipoDocumentoEmpresa,
  getDocumentosDescargaEmpresa,
  downloadDocumentosEmpresa,
  getVencimientoDocumentosEmpresa,
  getReporteDocumentosEmpresa,
  getEmpresaBitacora,
  putDocumentoEmpresaFechaVencimiento
};


function putDocumentoEmpresaFechaVencimiento(data) {
  return api.post(
    api.tx.putDocumentoEmpresaFechaVencimiento,
    data
  ).then(data => {
    return data;
  });
}

function getEmpresaBitacora(data) {
  return api.post(
    api.tx.getEmpresaBitacora,
    data
  ).then(data => {
    return data;
  });
}

function getIndicadoresEmpresa2(data) {
  return api.post(
    api.tx.getIndicadoresEmpresa2,
    data
  ).then(data => {
    return data;
  });
}

function getReporteDocumentosEmpresa(data){
  return api.post(
    api.tx.getReporteDocumentosEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function downloadDocumentosEmpresa(data){
  return api.post(
    api.tx.downloadDocumentosEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentosDescargaEmpresa(data){
  return api.post(
    api.tx.getDocumentosDescargaEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function postCategoriaDocumentoEmpresa(data){
  return api.post(
    api.tx.postCategoriaDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function putCategoriaDocumentoEmpresa(data){
  return api.post(
    api.tx.putCategoriaDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function postTipoDocumentoEmpresa(data){
  return api.post(
    api.tx.postTipoDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function putTipoDocumentoEmpresa(data){
  return api.post(
    api.tx.putTipoDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getConfiguracionDocumentosEmpresa(data){
  return api.post(
    api.tx.getConfiguracionDocumentosEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentosHistoricosEmpresa(data){
  return api.post(
    api.tx.getDocumentosHistoricosEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getCategoriaDocumentoEmpresa(data){
  return api.post(
    api.tx.getCategoriaDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getTipoDocumentoEmpresa(data){
  return api.post(
    api.tx.getTipoDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getDocBitacoraEmpresa(data){
  return api.post(
    api.tx.getDocBitacoraEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getEmpresa(data) {
  return api.post(
    api.tx.getEmpresa,
    data
  ).then(data => {
    return data;
  });
}

function putDocumentoEmpresa(data){
  return api.post(
    api.tx.putDocumentoEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function deleteDocumentoEmpresa(data){
  return api.post(
    api.tx.deleteDocumentoEmpresa, 
    {
      'id_cliente':userService.getUser().id_cliente,
      'id_empresa':data.id_empresa,
      'id_tipo':data.id_tipo,
      'id_doc':data.id_doc,
      'nombre_tipo':data.nombre_tipo,
      'id_usuario':userService.getUser().id_usuario,
      'nombre_usuario':userService.getUser().nombre_usuario,
    }
  ).then(data => {
    return data;
  });
}

function postEmpresa(data){
  return api.post(
    api.tx.postEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentosEmpresa(data){
  return api.post(
    api.tx.getDocumentosEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function putEmpresa(data){
  return api.post(
    api.tx.putEmpresa, 
    data
  ).then(data => {
    return data;
  });
}

function getVencimientoDocumentosEmpresa(data){
  return api.post(
    api.tx.getVencimientoDocumentosEmpresa,
    data
  ).then(data => {
    return data;
  });
}